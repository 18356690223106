import ShowActions from "../../components/actionButtons/ShowActions";
import React from "react";
import {
  BooleanField,
  Labeled,
  ReferenceField,
  Show,
  TextField,
} from "react-admin";
import { Card, Grid, Stack } from "@mui/material";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import CAMERA_FIELDS from "../../utils/constants/labels/CameraFields";
import LIVESTREAM_SETTINGS_FIELDS from "../../utils/constants/labels/LivestreamSettingsFields";

const LivestreamSettingsShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <Card>
        <Stack>
          <ContainerPadding padding="1% 2% 1% 2%">
            <Grid container flexDirection="column">
              <Labeled>
                <BooleanField
                  source={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.source}
                  label={LIVESTREAM_SETTINGS_FIELDS.streamingEnabled.label}
                />
              </Labeled>
              <Labeled>
                <BooleanField
                  source={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.source}
                  label={LIVESTREAM_SETTINGS_FIELDS.fullEnabled.label}
                />
              </Labeled>
              <Labeled>
                <BooleanField
                  source={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.source}
                  label={LIVESTREAM_SETTINGS_FIELDS.liteEnabled.label}
                />
              </Labeled>
              <Labeled>
                <BooleanField
                  source={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.source}
                  label={LIVESTREAM_SETTINGS_FIELDS.staticEnabled.label}
                />
              </Labeled>
              <Labeled>
                <BooleanField
                  source={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.source}
                  label={LIVESTREAM_SETTINGS_FIELDS.commentaryEnabled.label}
                />
              </Labeled>

              <Labeled>
                <ReferenceField
                  reference={LIVESTREAM_SETTINGS_FIELDS.courtId.reference}
                  source={LIVESTREAM_SETTINGS_FIELDS.courtId.source}
                  link="show"
                >
                  <TextField source="name" label="Court" />
                </ReferenceField>
              </Labeled>
            </Grid>
          </ContainerPadding>
        </Stack>
      </Card>
    </Show>
  );
};

export default LivestreamSettingsShow;
