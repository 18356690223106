import React, { useEffect, useState } from "react";
import { Link as LinkIcon } from "@mui/icons-material"; // or AttachFileIcon if you prefer

import {
  BooleanField,
  Show,
  TextField,
  ShowButton,
  useRecordContext,
  useDataProvider,
  useRedirect,
  ReferenceField,
  CreateButton,
} from "react-admin";
import ShowActions from "../../components/actionButtons/ShowActions";
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import COURT_FIELDS from "../../utils/constants/labels/courtFields";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import { useParams } from "react-router-dom";
import { useGetList } from "react-admin";
import RecordReader from "../../components/helpers/RecordReader";
import dataProviderIndex from "../../providers";
import { ASSIGN_CAMERA } from "../../utils/constants/CUSTOM_ACTIONS";
import RESOURCES from "../../utils/constants/resources";

const CourtShow = () => {
  const redirect = useRedirect();
  const { id: courtId } = useParams();
  const [hasStreamingSettings, setHasStreamingSettings] = useState(true);
  const [isAssignCameraOpen, setIsAssignCameraOpen] = useState(false);

  const { data: courtCameras, isLoading } = useGetList(
    "cameras",
    {
      filter: { courtId },
    },
    { retry: false },
  );
  const { data: cameras, isLoadingAll } = useGetList("cameras");

  return (
    <Show actions={<ShowActions />}>
      <RecordReader
        stateSetter={setHasStreamingSettings}
        valueToCheck={"livestreamSettings"}
      />
      <Card>
        <Stack>
          <ContainerPadding padding="1% 2% 1% 2%">
            <Grid container flexDirection="column">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 3fr",
                  gap: "8px",
                  rowGap: "14px",
                }}
              >
                <div>{COURT_FIELDS.name.label}</div>
                <TextField
                  source={COURT_FIELDS.name.source}
                  label={COURT_FIELDS.name.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.description.label}</div>
                <TextField
                  source={COURT_FIELDS.description.source}
                  label={COURT_FIELDS.description.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.courtConfigurationId.label}</div>
                <TextField
                  source={COURT_FIELDS.courtConfigurationId.source}
                  label={COURT_FIELDS.courtConfigurationId.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.address.label}</div>
                <TextField
                  source={COURT_FIELDS.address.source}
                  label={COURT_FIELDS.address.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.publicIp.label}</div>
                <TextField
                  source={COURT_FIELDS.publicIp.source}
                  label={COURT_FIELDS.publicIp.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.networkHardwareInstalled.label}</div>
                <TextField
                  source={COURT_FIELDS.networkHardwareInstalled.source}
                  label={COURT_FIELDS.networkHardwareInstalled.label}
                  emptyText="-"
                />

                <div>{COURT_FIELDS.livestreamEnabled.label}</div>
                <BooleanField
                  source={COURT_FIELDS.livestreamEnabled.source}
                  label={COURT_FIELDS.livestreamEnabled.label}
                />
              </div>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="h6"
                sx={{ paddingTop: 0, paddingBottom: "10px" }}
              >
                Organisations
              </Typography>

              <OrgsList />

              <Divider sx={{ my: 2 }} />

              <div>
                <Typography
                  variant="h6"
                  sx={{ paddingTop: 0, paddingBottom: "10px" }}
                >
                  Cameras
                </Typography>

                <div>
                  <CamerasList cameras={courtCameras} isLoading={isLoading} />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <CreateButton
                    resource="cameras"
                    to={`/cameras/create?courtId=${courtId}`}
                    label="New"
                  />
                  <CreateButton
                    label={isAssignCameraOpen ? "Close" : "Assign"}
                    icon={<LinkIcon />}
                    sx={{ width: 100, height: 30 }}
                    onClick={(e) => {
                      e.preventDefault(); // prevent default navigation
                      setIsAssignCameraOpen((prevState) => !prevState);
                    }}
                  />
                  {!isLoadingAll && !isLoading && (
                    <CameraAssignField
                      courtId={courtId}
                      isOpen={isAssignCameraOpen}
                      cameras={cameras}
                      courtCameras={courtCameras}
                    />
                  )}
                </div>
              </div>

              <Divider sx={{ my: 2 }} />

              <StreamingSettings
                hasStreamingSettings={hasStreamingSettings}
                courtId={courtId}
              />
            </Grid>
          </ContainerPadding>
        </Stack>
      </Card>
    </Show>
  );
};

const CamerasList = (params) => {
  const { cameras, isLoading } = params;

  if (isLoading) return null;

  return (
    <div>
      {cameras.map((camera) => (
        <div>
          <ShowButton
            resource="cameras"
            record={{ id: camera.id }}
            label={camera.role ? `${camera.type} ${camera.role}` : camera.type}
          />
        </div>
      ))}
    </div>
  );
};

const StreamingSettings = ({ hasStreamingSettings, courtId }) => {
  const record = useRecordContext();

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" sx={{ paddingTop: 0, paddingBottom: "10px" }}>
          {hasStreamingSettings && (
            <ReferenceField
              source="livestreamSettings.id"
              reference="livestreamSettings"
              link="edit"
            >
              <span>Streaming Settings</span>
            </ReferenceField>
          )}
        </Typography>
        {!hasStreamingSettings && (
          <CreateButton
            resource="livestreamSettings"
            to={`/livestreamSettings/create?courtId=${courtId}`}
            label="Streaming Settings"
          />
        )}
      </div>

      {hasStreamingSettings && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            gap: "8px",
            rowGap: "14px",
          }}
        >
          <div>{COURT_FIELDS.livestreamSettingsCommentaryEnabled.label}</div>
          <BooleanField
            source={COURT_FIELDS.livestreamSettingsCommentaryEnabled.source}
            label={COURT_FIELDS.livestreamSettingsCommentaryEnabled.label}
          />

          <div>{COURT_FIELDS.livestreamSettingsFullEnabled.label}</div>
          <BooleanField
            source={COURT_FIELDS.livestreamSettingsFullEnabled.source}
            label={COURT_FIELDS.livestreamSettingsFullEnabled.label}
          />

          <div>{COURT_FIELDS.livestreamSettingsLiteEnabled.label}</div>
          <BooleanField
            source={COURT_FIELDS.livestreamSettingsLiteEnabled.source}
            label={COURT_FIELDS.livestreamSettingsLiteEnabled.label}
          />

          <div>{COURT_FIELDS.livestreamSettingsStaticEnabled.label}</div>
          <BooleanField
            source={COURT_FIELDS.livestreamSettingsStaticEnabled.source}
            label={COURT_FIELDS.livestreamSettingsStaticEnabled.label}
          />
        </div>
      )}
    </div>
  );
};

const OrgsList = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadOrgs = async () => {
      if (!record?.orgIds) return;

      try {
        const results = await Promise.all(
          record.orgIds.map(async (id) => {
            const { data: club } = await dataProvider.getOne("clubs", { id });
            return club;
          }),
        );
        setData(results);
      } catch (error) {
        console.error("Error loading clubs:", error);
      } finally {
        setLoading(false);
      }
    };

    loadOrgs();
  }, [record, dataProvider]);

  if (!record) return null;
  if (loading) return <div>Loading...</div>;

  return (
    <div>
      {data.map((club) => (
        <div key={club.id}>
          <ShowButton
            resource="clubs"
            record={{ id: club.id }}
            label={club.name}
          />
        </div>
      ))}
    </div>
  );
};

const CameraAssignField = (params) => {
  const { courtId, isOpen, cameras, courtCameras } = params;
  const [selectedCameras, setSelectedCameras] = useState(courtCameras);

  if (!isOpen) return null;

  const handleSelectionChange = (event, value) => {
    const selectedIds = value.map((option) => option.id);
    setSelectedCameras(selectedIds);
  };
  const onSaveCamerasClick = async (assignedCameras) => {
    await dataProviderIndex(ASSIGN_CAMERA, RESOURCES.courts, {
      id: courtId,
      cams: assignedCameras,
    });
    window.location.reload();
  };

  return (
    <>
      <Autocomplete
        id="cameras-auto"
        options={cameras}
        multiple
        getOptionLabel={(option) =>
          `${option.serialNumber}, ${option.type}, ${option.role}`
        }
        style={{ width: 500 }}
        renderInput={(params) => (
          <MuiTextField {...params} label="Cameras" variant="outlined" />
        )}
        getOptionSelected={(option, value) => option.id === value.id}
        defaultValue={selectedCameras}
        onChange={handleSelectionChange}
      />
      <Button
        variant="contained"
        style={{ width: 50, height: 30 }}
        onClick={() => onSaveCamerasClick(selectedCameras)}
        disabled={!selectedCameras.length}
      >
        Save
      </Button>
    </>
  );
};

export default CourtShow;
