const COURT_FIELDS = {
  name: {
    source: "name",
    label: "Name",
  },
  description: {
    source: "description",
    label: "Description",
  },
  courtConfigurationId: {
    source: "courtConfigurationId",
    label: "Configuration ID",
  },
  address: {
    source: "address",
    label: "Address",
  },
  publicIp: {
    source: "publicIp",
    label: "Public IP",
  },
  networkHardwareInstalled: {
    source: "networkHardwareInstalled",
    label: "Network Hardware Installed",
  },
  livestreamEnabled: {
    source: "livestreamEnabled",
    label: "Livestream Enabled",
  },
  livestreamSettingsFullEnabled: {
    source: "livestreamSettings.fullEnabled",
    label: "Full",
  },
  livestreamSettingsLiteEnabled: {
    source: "livestreamSettings.liteEnabled",
    label: "Lite",
  },
  livestreamSettingsStaticEnabled: {
    source: "livestreamSettings.staticEnabled",
    label: "Static",
  },
  livestreamSettingsCommentaryEnabled: {
    source: "livestreamSettings.commentaryEnabled",
    label: "Commentary",
  },
  livestreamSettingsId: {
    source: "livestreamSettings.id",
    label: "Streaming Settings Id",
  },
  cameras: {
    source: "cameras",
    label: "cameras",
  },
};

export default COURT_FIELDS;
