import ShowActions from "../../components/actionButtons/ShowActions";
import React from "react";
import { Labeled, ReferenceField, Show, TextField } from "react-admin";
import { Card, Grid, Stack } from "@mui/material";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import CAMERA_FIELDS from "../../utils/constants/labels/CameraFields";

const CameraShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <Card>
        <Stack>
          <ContainerPadding padding="1% 2% 1% 2%">
            <Grid container flexDirection="column">
              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.username.source}
                  label={CAMERA_FIELDS.username.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.serialNumber.source}
                  label={CAMERA_FIELDS.serialNumber.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.modelDescription.source}
                  label={CAMERA_FIELDS.modelDescription.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.rtspPort.source}
                  label={CAMERA_FIELDS.rtspPort.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.apiPort.source}
                  label={CAMERA_FIELDS.apiPort.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.hasLineIn.source}
                  label={CAMERA_FIELDS.hasLineIn.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.type.source}
                  label={CAMERA_FIELDS.type.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CAMERA_FIELDS.role.source}
                  label={CAMERA_FIELDS.role.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <ReferenceField
                  reference={CAMERA_FIELDS.courtId.reference}
                  source={CAMERA_FIELDS.courtId.source}
                  link="show"
                >
                  <TextField source="name" label="Court" />
                </ReferenceField>
              </Labeled>
            </Grid>
          </ContainerPadding>
        </Stack>
      </Card>
    </Show>
  );
};

export default CameraShow;
