import { Admin, Resource } from "react-admin";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import VideocamIcon from "@mui/icons-material/Videocam";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import Settings from "@mui/icons-material/Settings";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import UserCreate from "./pages/create/UserCreate";
import CourtCreate from "./pages/create/CourtCreate";
import UserList from "./pages/list/UserList/UserList";
import CourtList from "./pages/list/CourtList/CourtList";
import UserShow from "./pages/show/UserShow";
import CourtShow from "./pages/show/CourtShow";
import authProvider from "./providers/authProvider";
import dataProviderIndex from "./providers";
import CourtEdit from "./pages/edit/CourtEdit";
import UserEdit from "./pages/edit/UserEdit";
import LivestreamList from "./pages/list/LivestreamList/LivestreamList";
import LivestreamShow from "./pages/show/LivestreamShow";
import LoginPage from "./pages/Login/LoginPage";
import RESOURCES from "./utils/constants/resources";
import ClubList from "./pages/list/ClubList/ClubList";
import ClubShow from "./pages/show/ClubShow";
import ClubCreate from "./pages/create/ClubCreate";
import ClubEdit from "./pages/edit/ClubEdit";
import CameraList from "./pages/list/CameraList/CameraList";
import CameraShow from "./pages/show/CameraShow";
import CameraForm from "./components/forms/CameraForm";
import CameraCreate from "./pages/create/CameraCreate";
import CameraEdit from "./pages/edit/CameraEdit";
import LivestreamSettingsList from "./pages/list/LivestreamSettings/LivestreamSettingsList";
import LivestreamSettingsCreate from "./pages/create/LivetsreamSettingsCreate";
import LivestreamSettingsEdit from "./pages/edit/LivestreamSettingsEdit";
import LivestreamSettingsShow from "./pages/show/LivestreamSettingsShow";

const App = () => {
  return (
    <div>
      <Admin
        dataProvider={dataProviderIndex}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        <Resource
          name={RESOURCES.users}
          icon={AccountCircleIcon}
          options={{ label: "Users" }}
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          show={UserShow}
        />
        <Resource
          name={RESOURCES.clubs}
          icon={MapsHomeWorkIcon}
          recordRepresentation="name"
          list={ClubList}
          show={ClubShow}
          create={ClubCreate}
          edit={ClubEdit}
        />
        <Resource
          name={RESOURCES.courts}
          icon={SportsBasketballIcon}
          options={{ label: "Courts" }}
          list={CourtList}
          create={CourtCreate}
          edit={CourtEdit}
          show={CourtShow}
        />
        <Resource
          name={RESOURCES.cameras}
          icon={VideocamIcon}
          options={{ label: "Cameras" }}
          list={CameraList}
          create={CameraCreate}
          edit={CameraEdit}
          show={CameraShow}
        />
        <Resource
          name={RESOURCES.livestreams}
          icon={SettingsInputAntennaIcon}
          options={{ label: "Livestreams" }}
          list={LivestreamList}
          show={LivestreamShow}
        />
        <Resource
          name={RESOURCES.livestreamSettings}
          icon={Settings}
          show={LivestreamSettingsShow}
          create={LivestreamSettingsCreate}
          edit={LivestreamSettingsEdit}
        />
        <Resource name={RESOURCES.userRoles} recordRepresentation="name" />
        <Resource name={RESOURCES.clubScopes} recordRepresentation="name" />
        <Resource name={RESOURCES.countries} recordRepresentation="name" />
      </Admin>
    </div>
  );
};

export default App;
